import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <h4>MyCalci is a free app. This SERVICE is provided by MyCalci at no cost and is intended for use as is.</h4> 
      <h4>Privacy Policy for MyCalci App</h4>
      <ul>
        <li>
        This app is suitable for users of all ages and does not collect any personal information or make any API calls.
        </li>
        <br/>
        <li>
        The app does not collect any personally identifiable information or any other data from its users.
        </li>
        <br/>

        <li>
        The app does not log any data from its users.
        </li>
        <br/>

        <li>
        While no method of transmission over the internet or electronic storage is 100% secure, we strive to use commercially acceptable means to protect your information. However, please note that we do not collect any information from you in the first place.
        </li>
        <br/>

        <li>
        This Service does not contain links to other sites.
        </li>
        <br/>

        <li>
        This app is suitable for users of all ages, including children under the age of 13, as it does not collect any information from users.
        </li>
        <br/>

        <li>
          If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <b>msshinde106@gmail.com</b>
        </li>
        <br/>

      </ul>
      </header>
    </div>
  );
}

export default App;
